import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import Link from 'next/link';
import Router from 'next/router';
import classNames from 'classnames';
import getConfig from 'next/config';
import moment from 'moment';

import styles from './Header.module.scss';

import Modal from '@/components/Base/Modal';
import EventModal from '@/components/Calendar/EventModal';
import ThemeSwitcher from '@/components/Profile/ThemeSwitcher';
import TrainingCertification from '@/components/Profile/TrainingCertification';
import { EVENT_APPOINTMENT, EVENT_FOLLOW_UP, EVENT_TASK, EVENT_OTHER, EVENT_UNAVAILABLE } from '@/store/models/event';
import { config } from '@/config';
import { logOut } from '@/store/actions/session.actions';
import { logBack } from '@/store/actions/admin/users.actions';
import { setSessionItem, getSessionItem, removeSessionItem } from '@/utils/storage';
import { setSearchContactToStore, clearContacts } from '@/store/actions/contacts.actions';
import { setStateAsync } from '@/utils/helpers';
import { abortSignal } from '@/utils/request';

import LogoutButton from '@/components/LogoutButton';

import * as gtag from '@/utils/analytics';
import { getLoginStack } from '@/utils/storage';
import { fetchAPIKey, refreshAPIKey, updateProfileFields } from '@/store/actions/currentUser.actions';

const { publicRuntimeConfig } = getConfig();

const LinkWrapper = ({ children, href }) => {
  if (window.location.pathname === href) return children;
  return <Link href={href}>{children}</Link>;
};

const SHOW_ENV_FOR = ['stage', 'development'];
const SHOW_ENV_FOR_USERNAMES = ['teamvoy_admin'];

class Header extends Component {
  state = {
    pathName: '',
    eventType: '',
    themeModalVisible: false,
    createEventModalIsVisible: false,
    trainingCertificationModalVisible: false
  };

  setStateAsync = setStateAsync.bind(this);

  get isRM() {
    return this.props.currentUser.regional_manager;
  }

  async componentDidMount() {
    await this.setStateAsync({ pathName: window.location.pathname });
    await this.setRootClass();
    const search = getSessionItem('search');
    if (search) await this.props.setSearchContactToStore(search);
    if (typeof window !== 'undefined') {
      gtag.setuser(this.props.currentUser);
    }
    Router.events.on('routeChangeComplete', url => {
      gtag.pageview(url);
    });
    if (config().TRAINING_APP && config().TRAINING_REQUIRED) {
      if (this.props.currentAccount['paid_user?'] && !this.props.currentUser.profile.training_viewed_at) {
        this.setState({ trainingCertificationModalVisible: true });
      }
    }
  }

  componentWillUnmount() {
    this.removeRootClass();
  }

  get loggedAsUser() {
    const login_type = getLoginStack().peek();
    return login_type === 'impersonating_as_rm' || login_type === 'impersonating' || login_type === 'logged_as_admin';
  }

  get isRestrictedUser() {
    return (
      (this.props.currentAccount &&
        (!this.props.currentAccount['paid_user?'] || this.props.currentAccount['change_password?'])) ||
      (this.props.currentUser['two_fa_is_required?'] &&
        !this.props.currentUser['has_2fa_enabled?'] &&
        !this.loggedAsUser)
    );
  }

  get pages() {
    const { pathName } = this.state;
    const forRM = Router.query.category === 'RM';
    const { currentUser } = this.props;

    return (
      <div className={classNames(styles.pages)}>
        <div className={classNames('navbar-wrapper', styles.navbarWrapper)}>
          {!this.isRestrictedUser && (
            <>
              {(SHOW_ENV_FOR.includes(publicRuntimeConfig.APP_ENV) ||
                (publicRuntimeConfig.APP_ENV === 'demo' && SHOW_ENV_FOR_USERNAMES.includes(currentUser.username))) && (
                <p className={classNames(styles.featureFlag)}>{config().RAILS_ENV?.replace('production_', '')}</p>
              )}

              {!this.isRM && (
                <LinkWrapper href="/">
                  <div
                    onClick={() => {
                      if (pathName !== '/') abortSignal();
                    }}
                    className={classNames(styles.navbarItem, pathName === '/' && styles.active)}
                  >
                    <p>Home</p>
                  </div>
                </LinkWrapper>
              )}

              <LinkWrapper href="/contacts">
                <div
                  className={classNames(
                    styles.navbarItem,
                    pathName.includes('/contacts') && !pathName.includes('/import') && styles.active
                  )}
                  onClick={async () => {
                    if (pathName !== '/contacts') {
                      abortSignal();
                      removeSessionItem('category');
                    }
                  }}
                >
                  <p>Contacts</p>
                </div>
              </LinkWrapper>

              <LinkWrapper href="/calendar">
                <div
                  className={classNames(
                    styles.navbarItem,
                    pathName.includes('/calendar') && !pathName.includes('/reports') && styles.active
                  )}
                  onClick={() => {
                    if (pathName !== '/calendar') abortSignal();
                  }}
                >
                  <p>Calendar</p>
                </div>
              </LinkWrapper>

              {config().REPORTS && (
                <LinkWrapper href="/reports/list">
                  <div
                    className={classNames(styles.navbarItem, pathName.includes('/reports/list') && styles.active)}
                    onClick={() => {
                      if (pathName !== '/reports/list') abortSignal();
                    }}
                  >
                    <p>Reports</p>
                  </div>
                </LinkWrapper>
              )}

              {currentUser.admin && config().RM ? (
                <div
                  className={classNames(
                    'navbar-item with-dropdown',
                    styles.navbarItem,
                    styles.withDropdown,
                    pathName.startsWith('/training') && styles.active
                  )}
                >
                  <p className={styles.withIcon}>
                    Training <i className="fas fa-chevron-down" />
                  </p>
                  <div className={styles.navsDropdown}>
                    <Link href="/training?category=all">
                      <div
                        className={classNames(
                          styles.navbarItem,
                          pathName.startsWith('/training') && !forRM && styles.active
                        )}
                      >
                        <p>Users</p>
                      </div>
                    </Link>
                    <Link href="/training?category=RM">
                      <div
                        className={classNames(
                          styles.navbarItem,
                          pathName.startsWith('/training') && forRM && styles.active
                        )}
                      >
                        <p>RM Users</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                <div
                  className={classNames(
                    'navbar-item with-dropdown',
                    styles.navbarItem,
                    styles.withDropdown,
                    pathName.startsWith('/training') && styles.active
                  )}
                  onClick={() => {
                    if (pathName !== '/training') abortSignal();
                  }}
                >
                  <p className={styles.withIcon}>
                    Training <i className="fas fa-chevron-down" />
                  </p>

                  <div className={styles.navsDropdown}>
                    <Link href="/training">
                      <div className={classNames(styles.navbarItem, pathName.includes('/training') && styles.active)}>
                        <p>Library</p>
                      </div>
                    </Link>
                    {config().TRAINING_APP && (
                      <div className={classNames(styles.navbarItem)} role="link" onClick={this.navigateToTraining}>
                        <p>Certification</p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {((config(currentUser).SMS_FEATURE &&
                (!config(currentUser).SMS_DISABLED || !config(currentUser).DIALER_DISABLED)) ||
                currentUser.profile.dialer_enabled ||
                (config().ASB_SYSTEM && !config().BROKERAGEASB && (currentUser.admin || currentUser.manager)) ||
                config().SOA_REQUESTS) && (
                <div
                  className={classNames(
                    'navbar-item with-dropdown',
                    styles.navbarItem,
                    styles.withDropdown,
                    (pathName.includes('/sms/') || pathName.includes('/messages')) && styles.active
                  )}
                >
                  <p className={styles.withIcon}>
                    Campaigns
                    <i className="fas fa-chevron-down" />
                  </p>
                  <div className={classNames(styles.navsDropdown, styles.navsDropdownCampaigns)}>
                    {config().ASB_SYSTEM && !config().BROKERAGEASB && (currentUser.admin || currentUser.manager) && (
                      <div
                        className={classNames(
                          'nested navbar-item with-dropdown',
                          styles.nested,
                          styles.navbarItem,
                          styles.withDropdown,
                          pathName.includes('/campaigns/leads/') && styles.active
                        )}
                      >
                        <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                          <a>
                            <p>Leads</p>
                          </a>
                        </div>
                        <div className={styles.nestedDropdown}>
                          <Link href="/campaigns/leads/mailer-assignment">
                            <div
                              className={classNames(
                                styles.navbarItem,
                                pathName.includes('/campaigns/leads/mailer-assignment') && styles.active
                              )}
                            >
                              <p>Mailer Assignment</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                    {config().SOA_REQUESTS && (
                      <div
                        className={classNames(
                          'nested navbar-item with-dropdown',
                          styles.nested,
                          styles.navbarItem,
                          styles.withDropdown,
                          pathName.includes('/campaigns/aep/') && styles.active
                        )}
                      >
                        <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                          <a>
                            <p>AEP</p>
                          </a>
                        </div>
                        <div className={styles.nestedDropdown}>
                          <Link href="/campaigns/aep/soa">
                            <div
                              className={classNames(
                                styles.navbarItem,
                                pathName.includes('/campaigns/aep/soa') && styles.active
                              )}
                            >
                              <p>SOA</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                    {config(currentUser).SMS_FEATURE && !config(currentUser).SMS_DISABLED && (
                      <div
                        className={classNames(
                          'nested navbar-item with-dropdown',
                          styles.nested,
                          styles.navbarItem,
                          styles.withDropdown,
                          (pathName.includes('/sms/') || pathName.includes('/messages')) && styles.active
                        )}
                      >
                        <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                          <a>
                            <p>SMS</p>
                          </a>
                        </div>
                        <div className={styles.nestedDropdown}>
                          {/* Conversations */}
                          {['active', 'grace'].includes(currentUser.sms_subscription?.status) &&
                            currentUser.profile.dialer_verified_phone_number &&
                            ['Subscription::ManagerPay', 'Subscription::SelfPay'].includes(
                              currentUser.sms_subscription?.order_type
                            ) && (
                              <Link href="/sms/conversations">
                                <div
                                  className={classNames(
                                    styles.navbarItem,
                                    pathName.includes('/sms/conversations') && styles.active
                                  )}
                                >
                                  <p>Conversations</p>
                                </div>
                              </Link>
                            )}

                          {/* Enroll */}
                          {!currentUser.enable_enterprise_setup &&
                            !['active', 'grace'].includes(currentUser.sms_subscription?.status) && (
                              <Link href="/sms/setup">
                                <div
                                  className={classNames(
                                    styles.navbarItem,
                                    pathName.includes('/sms/setup') &&
                                      !pathName.includes('/sms/setup/company') &&
                                      !pathName.includes('/sms/setup/options') &&
                                      styles.active
                                  )}
                                >
                                  <p>Enroll</p>
                                </div>
                              </Link>
                            )}

                          {currentUser.enable_enterprise_setup &&
                            !['active', 'grace'].includes(currentUser.company_sms_subscription?.status) && (
                              <Link href="/sms/setup/options">
                                <div
                                  className={classNames(
                                    styles.navbarItem,
                                    pathName.includes('/sms/setup/options') && styles.active
                                  )}
                                >
                                  <p>Enroll</p>
                                </div>
                              </Link>
                            )}
                          {(config().HRGAGENT ||
                            ['active', 'grace'].includes(currentUser.company_sms_subscription?.status) ||
                            ['active', 'grace'].includes(currentUser.sms_subscription?.status)) && (
                            <>
                              <Link href="/sms/bulk-sms-verification">
                                <div
                                  className={classNames(
                                    styles.navbarItem,
                                    pathName.includes('/sms/bulk-sms-verification') && styles.active
                                  )}
                                >
                                  <p>Bulk Verification</p>
                                </div>
                              </Link>
                              <Link href="/sms/phone-verification-maganement">
                                <div
                                  className={classNames(
                                    styles.navbarItem,
                                    pathName.includes('/sms/phone-verification-maganement') && styles.active
                                  )}
                                >
                                  <p>Verification Management</p>
                                </div>
                              </Link>
                            </>
                          )}
                          {(['active', 'grace'].includes(currentUser.company_sms_subscription?.status) ||
                            ['active', 'grace'].includes(currentUser.sms_subscription?.status)) && (
                            <>
                              <Link href="/sms/messages">
                                <div
                                  className={classNames(
                                    styles.navbarItem,
                                    pathName.includes('/sms/messages') && styles.active
                                  )}
                                >
                                  <p>Messages</p>
                                </div>
                              </Link>
                              <Link href="/sms/subscriptions">
                                <div
                                  className={classNames(
                                    styles.navbarItem,
                                    pathName.includes('/sms/subscriptions') && styles.active
                                  )}
                                >
                                  <p>My Subscription</p>
                                </div>
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {((config(currentUser).SMS_FEATURE && !config(currentUser).DIALER_DISABLED) ||
                      currentUser.profile.dialer_enabled) && (
                      <div
                        className={classNames(
                          'nested navbar-item with-dropdown',
                          styles.nested,
                          styles.navbarItem,
                          styles.withDropdown,
                          pathName.includes('/dialer/') && styles.active
                        )}
                      >
                        <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                          <a>
                            <p>Dialer</p>
                          </a>
                        </div>
                        <div className={styles.nestedDropdown}>
                          {/* Conversations */}
                          {((currentUser.profile.dialer_enabled && currentUser.profile.dialer_verified_phone_number) ||
                            (['active', 'grace'].includes(currentUser.dialer_subscription?.status) &&
                              currentUser.profile.dialer_verified_phone_number &&
                              ['Subscription::ManagerPay', 'Subscription::SelfPay'].includes(
                                currentUser.dialer_subscription?.order_type
                              ))) && (
                            <Link href="/dialer/logs">
                              <div
                                className={classNames(
                                  styles.navbarItem,
                                  pathName.includes('/dialer/logs') && styles.active
                                )}
                              >
                                <p>Call History</p>
                              </div>
                            </Link>
                          )}

                          {/* Enroll */}
                          {!currentUser.enable_enterprise_setup &&
                            !['active', 'grace'].includes(currentUser.dialer_subscription?.status) && (
                              <Link href="/sms/setup">
                                <div
                                  className={classNames(
                                    styles.navbarItem,
                                    pathName.includes('/sms/setup') &&
                                      !pathName.includes('/sms/setup/company') &&
                                      !pathName.includes('/sms/setup/options') &&
                                      styles.active
                                  )}
                                >
                                  <p>Enroll</p>
                                </div>
                              </Link>
                            )}

                          {config(currentUser).SMS_FEATURE &&
                            currentUser.enable_enterprise_setup &&
                            !['active', 'grace'].includes(currentUser.company_dialer_subscription?.status) && (
                              <Link href="/sms/setup/options">
                                <div
                                  className={classNames(
                                    styles.navbarItem,
                                    pathName.includes('/sms/setup/options') && styles.active
                                  )}
                                >
                                  <p>Enroll</p>
                                </div>
                              </Link>
                            )}

                          {/* Subscription */}
                          {(['active', 'grace'].includes(currentUser.dialer_subscription?.status) ||
                            ['active', 'grace'].includes(currentUser.company_dialer_subscription?.status)) && (
                            <Link href="/dialer/subscriptions">
                              <div
                                className={classNames(
                                  styles.navbarItem,
                                  pathName.includes('/dialer/subscriptions') && styles.active
                                )}
                              >
                                <p>My Subscription</p>
                              </div>
                            </Link>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className={classNames('navbar-item with-dropdown', styles.navbarItem, styles.withDropdown)}>
                <a
                  className={classNames(
                    styles.navbarDropped,
                    styles.iconOnly,
                    styles.settingsIconWrapper,
                    pathName.includes('uploader') && styles.active
                  )}
                >
                  <i className={classNames(styles.settingsIcon, 'mbri-setting3')} />
                </a>
                <div className={styles.navsDropdown}>
                  <div
                    onClick={() => this.setState({ themeModalVisible: !this.state.themeModalVisible })}
                    className={styles.navbarItem}
                  >
                    <a>
                      <p>Theme</p>
                    </a>
                  </div>
                  {(currentUser.admin || currentUser.sub_admin) && (
                    <>
                      <div
                        className={classNames(styles.navbarItem, pathName.includes('/leads-uploader') && styles.active)}
                      >
                        <Link href="/contacts/import/leads-uploader">
                          <p>Leads Uploader</p>
                        </Link>
                      </div>
                      {!currentUser.sub_admin && (
                        <div
                          className={classNames(
                            styles.navbarItem,
                            pathName.includes('/clients-uploader') && styles.active
                          )}
                        >
                          <Link href="/contacts/import/clients-uploader">
                            <p>Clients Uploader</p>
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              {config(currentUser).SMS_FEATURE &&
                !config(currentUser).SMS_DISABLED &&
                ['active', 'grace'].includes(currentUser.sms_subscription?.status) && (
                  <div className={classNames('navbar-item with-dropdown', styles.navbarItem, styles.withDropdown)}>
                    <Link href="/sms/conversations" className={classNames(styles.navbarDropped, styles.iconOnly)}>
                      {currentUser.new_messages ? (
                        <i className={classNames(styles.bellIcon, 'far fa-bell')}>
                          <i className="unread-notifications-circle-nav" />
                        </i>
                      ) : (
                        <i className={classNames(styles.bellIcon, 'far fa-bell')} />
                      )}
                    </Link>
                  </div>
                )}

              {currentUser.admin && (
                <div
                  className={classNames(
                    'navbar-item with-dropdown',
                    styles.navbarItem,
                    styles.withDropdown,
                    pathName.includes('/admin') && styles.active
                  )}
                >
                  <p className={styles.withIcon}>
                    {currentUser.sub_admin ? 'Admin' : 'System Admin'} <i className="fas fa-chevron-down" />
                  </p>
                  <div className={styles.navsDropdown}>
                    <Link href="/admin/users">
                      <div className={classNames(styles.navbarItem, pathName.includes('/users') && styles.active)}>
                        <p>User Management</p>
                      </div>
                    </Link>
                    {config().MARKETING_PROFILE && !currentUser.sub_admin && (
                      <Link href="/admin/signups">
                        <div
                          className={classNames(
                            styles.navbarItem,
                            pathName.includes('/admin/signups') && styles.active
                          )}
                        >
                          <p>Sign Up</p>
                        </div>
                      </Link>
                    )}
                    {!currentUser.sub_admin && (
                      <Link href="/admin/orders">
                        <div className={classNames(styles.navbarItem, pathName.includes('/orders') && styles.active)}>
                          <p>Order Management</p>
                        </div>
                      </Link>
                    )}

                    <Link href="/admin/transfers">
                      <div className={classNames(styles.navbarItem, pathName.includes('/transfers') && styles.active)}>
                        <p>Transfer Management</p>
                      </div>
                    </Link>

                    {!currentUser.sub_admin && !config().BROKERAGEASB && (
                      <div
                        className={classNames(
                          'nested navbar-item with-dropdown',
                          styles.nested,
                          styles.navbarItem,
                          styles.withDropdown,
                          (pathName.includes('/admin/email_campaign_triggers') ||
                            pathName.includes('/admin/email-management')) &&
                            styles.active
                        )}
                      >
                        <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                          <a>
                            <p>Email Management</p>
                          </a>
                        </div>
                        <div className={styles.nestedDropdown}>
                          <Link href="/admin/email-management">
                            <div
                              className={classNames(
                                styles.navbarItem,
                                pathName.includes('/admin/email-management') && styles.active
                              )}
                            >
                              <p>Email Templates</p>
                            </div>
                          </Link>
                          {config(currentUser).EMAIL_CAMPAIGNS && (
                            <Link href="/admin/email_campaign_triggers">
                              <div
                                className={classNames(
                                  styles.navbarItem,
                                  pathName.includes('/admin/email_campaign_triggers') && styles.active
                                )}
                              >
                                <p>Campaign</p>
                              </div>
                            </Link>
                          )}
                        </div>
                      </div>
                    )}
                    {config(currentUser).SMS_FEATURE &&
                      !config(currentUser).DIALER_DISABLED &&
                      !currentUser.sub_admin && (
                        <div
                          className={classNames(
                            'nested navbar-item with-dropdown',
                            styles.nested,
                            styles.navbarItem,
                            styles.withDropdown,
                            pathName.includes('/admin/dialer-management') && styles.active
                          )}
                        >
                          <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                            <p>Dialer Management</p>
                          </div>
                          <div className={styles.nestedDropdown}>
                            <Link href="/admin/dialer-management">
                              <div
                                className={classNames(
                                  styles.navbarItem,
                                  pathName.includes('/admin/dialer-management') && styles.active
                                )}
                              >
                                <p>Management</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      )}

                    {config(currentUser).SMS_FEATURE && !config(currentUser).SMS_DISABLED && !currentUser.sub_admin && (
                      <div
                        className={classNames(
                          'nested navbar-item with-dropdown',
                          styles.nested,
                          styles.navbarItem,
                          styles.withDropdown,
                          (pathName.includes('/sms/plans') ||
                            pathName.includes('/admin/campaign_triggers') ||
                            pathName.includes('/admin/sms-management')) &&
                            styles.active
                        )}
                      >
                        <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                          <a>
                            <p>SMS Management</p>
                          </a>
                        </div>
                        <div className={styles.nestedDropdown}>
                          <Link href="/sms/plans">
                            <div
                              className={classNames(
                                styles.navbarItem,
                                pathName.includes('/sms/plans') && styles.active
                              )}
                            >
                              <p>Plans</p>
                            </div>
                          </Link>
                          {config(currentUser).CAMPAIGNS_TRIGGERS_ADMIN_TAB && (
                            <Link href="/admin/campaign_triggers">
                              <div
                                className={classNames(
                                  styles.navbarItem,
                                  pathName.includes('/admin/campaign_triggers') && styles.active
                                )}
                              >
                                <p>Campaign</p>
                              </div>
                            </Link>
                          )}

                          <Link href="/admin/sms-management">
                            <div
                              className={classNames(
                                styles.navbarItem,
                                pathName.includes('/admin/sms-management') && styles.active
                              )}
                            >
                              <p>Management</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}

                    {!currentUser.sub_admin && (
                      <div
                        className={classNames(
                          'nested navbar-item with-dropdown',
                          styles.nested,
                          styles.navbarItem,
                          styles.withDropdown,
                          pathName.includes('/product-management') && styles.active
                        )}
                      >
                        <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                          <a>
                            <p>Product Management</p>
                          </a>
                        </div>
                        <div className={styles.nestedDropdown}>
                          <Link href="/admin/product-management/products">
                            <div
                              className={classNames(styles.navbarItem, pathName.includes('/products') && styles.active)}
                            >
                              <p>Products</p>
                            </div>
                          </Link>
                          <Link href="/admin/product-management/categories">
                            <div
                              className={classNames(
                                styles.navbarItem,
                                pathName.includes('/categories') && styles.active
                              )}
                            >
                              <p>Categories</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                    {!config().DISABLED_POOLS && (
                      <div
                        className={classNames(
                          'nested navbar-item with-dropdown',
                          styles.nested,
                          styles.navbarItem,
                          styles.withDropdown,
                          pathName.includes('/pools-management') && styles.active
                        )}
                      >
                        <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                          <a>
                            <p>Pool Management</p>
                          </a>
                        </div>
                        <div className={styles.nestedDropdown}>
                          <Link href="/admin/pools-management/groups">
                            <div
                              className={classNames(styles.navbarItem, pathName.includes('/groups') && styles.active)}
                            >
                              <p>Groups</p>
                            </div>
                          </Link>
                          <Link href="/admin/pools-management/pools">
                            <div
                              className={classNames(
                                styles.navbarItem,
                                pathName.includes('/pools-management/pools') && styles.active
                              )}
                            >
                              <p>Pools</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                    {!currentUser.sub_admin && (
                      <>
                        <Link href="/admin/carriers">
                          <div
                            className={classNames(styles.navbarItem, pathName.includes('/carriers') && styles.active)}
                          >
                            <p>Insurance Carriers</p>
                          </div>
                        </Link>
                        <Link href="/admin/statuses">
                          <div
                            className={classNames(styles.navbarItem, pathName.includes('/statuses') && styles.active)}
                          >
                            <p>Statuses</p>
                          </div>
                        </Link>
                        <Link href="/admin/sources">
                          <div
                            className={classNames(styles.navbarItem, pathName.includes('/sources') && styles.active)}
                          >
                            <p>Sources</p>
                          </div>
                        </Link>
                        <Link href="/admin/company">
                          <div
                            className={classNames(styles.navbarItem, pathName.includes('/company') && styles.active)}
                          >
                            <p>Companies</p>
                          </div>
                        </Link>
                      </>
                    )}
                    {config().BUSINESS_UNIT && (
                      <Link href="/admin/business-unit">
                        <div
                          className={classNames(
                            styles.navbarItem,
                            pathName.includes('/business-unit') && styles.active
                          )}
                        >
                          <p>Business Units</p>
                        </div>
                      </Link>
                    )}
                    {!currentUser.sub_admin &&
                      (config().RM ? (
                        <div
                          className={classNames(
                            'nested navbar-item with-dropdown',
                            styles.nested,
                            styles.navbarItem,
                            styles.withDropdown,
                            pathName.includes('admin/training') && styles.active
                          )}
                        >
                          <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                            <a>
                              <p>Training</p>
                            </a>
                          </div>
                          <div className={styles.nestedDropdown}>
                            <Link href="/admin/training?category=all">
                              <div
                                className={classNames(
                                  styles.navbarItem,
                                  pathName.includes('admin/training') && !forRM && styles.active
                                )}
                              >
                                <p>Users</p>
                              </div>
                            </Link>
                            <Link href="/admin/training?category=RM">
                              <div
                                className={classNames(
                                  styles.navbarItem,
                                  pathName.includes('admin/training') && forRM && styles.active
                                )}
                              >
                                <p>RM Users</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <Link href="/admin/training">
                          <div
                            className={classNames(
                              styles.navbarItem,
                              pathName.includes('/admin/training') && styles.active
                            )}
                          >
                            <p>Training</p>
                          </div>
                        </Link>
                      ))}
                    <div
                      className={classNames(
                        'nested navbar-item with-dropdown',
                        styles.nested,
                        styles.navbarItem,
                        styles.withDropdown,
                        pathName.includes('admin/api-tracking') && styles.active
                      )}
                    >
                      <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                        <a>
                          <p>API Tracking</p>
                        </a>
                      </div>
                      <div className={styles.nestedDropdown}>
                        <Link href="/admin/api-tracking/vendors">
                          <div
                            className={classNames(
                              styles.navbarItem,
                              pathName.includes('/admin/api-tracking/vendors') && styles.active
                            )}
                          >
                            <p>Vendors</p>
                          </div>
                        </Link>
                        <Link href="/admin/api-tracking/keys">
                          <div
                            className={classNames(
                              styles.navbarItem,
                              pathName.includes('/admin/api-tracking/keys') && styles.active
                            )}
                          >
                            <p>API Keys</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                    {publicRuntimeConfig.FEATURE_FLAGS && (
                      <a href={'/admin/flipflop'} target="_blank" rel="noreferrer">
                        <div className={classNames(styles.navbarItem, pathName.includes('/flipflop') && styles.active)}>
                          <p>Feature Flags</p>
                        </div>
                      </a>
                    )}
                    {!currentUser.sub_admin && (
                      <Link href="/admin/settings">
                        <div
                          className={classNames(
                            styles.navbarItem,
                            pathName.includes('/admin/settings') && styles.active
                          )}
                        >
                          <p>Settings</p>
                        </div>
                      </Link>
                    )}
                    {['demo', 'stage', 'development'].includes(publicRuntimeConfig.APP_ENV) &&
                      ['jimhill', 'teamvoy_admin'].includes(currentUser.username) && (
                        <div
                          className={classNames(
                            'nested navbar-item with-dropdown',
                            styles.nested,
                            styles.navbarItem,
                            styles.withDropdown,
                            pathName.includes('/statistics') && styles.active
                          )}
                        >
                          <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                            <a>
                              <p>Statistics</p>
                            </a>
                          </div>
                          <div className={styles.nestedDropdown}>
                            <Link href="/admin/statistics/general">
                              <div
                                className={classNames(
                                  styles.navbarItem,
                                  pathName.includes('/general') && styles.active
                                )}
                              >
                                <p>General</p>
                              </div>
                            </Link>
                            <Link href="/admin/statistics/errors">
                              <div
                                className={classNames(styles.navbarItem, pathName.includes('/errors') && styles.active)}
                              >
                                <p>Errors</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
              {currentUser.bu_admin && (
                <div
                  className={classNames(
                    'navbar-item with-dropdown',
                    styles.navbarItem,
                    styles.withDropdown,
                    pathName.includes('/admin') && styles.active
                  )}
                >
                  <p className={styles.withIcon}>
                    BU Admin <i className="fas fa-chevron-down" />
                  </p>
                  <div className={styles.navsDropdown}>
                    <Link href="/admin/users">
                      <div className={classNames(styles.navbarItem, pathName.includes('/users') && styles.active)}>
                        <p>User Management</p>
                      </div>
                    </Link>
                    {!currentUser.sub_admin && !config().BROKERAGEASB && (
                      <Link href="/admin/email-management">
                        <div
                          className={classNames(
                            styles.navbarItem,
                            pathName.includes('/email-management') && styles.active
                          )}
                        >
                          <p>Email Management</p>
                        </div>
                      </Link>
                    )}
                    <Link href="/admin/product-management/products">
                      <div
                        className={classNames(
                          styles.navbarItem,
                          pathName.includes('/admin/product-management/products') && styles.active
                        )}
                      >
                        <p>Product Management</p>
                      </div>
                    </Link>
                    {config(currentUser).CAMPAIGNS_TRIGGERS_ADMIN_TAB && (
                      <Link href="/admin/campaign_triggers">
                        <div
                          className={classNames(
                            styles.navbarItem,
                            pathName.includes('/admin/campaign_triggers') && styles.active
                          )}
                        >
                          <p>Campaign</p>
                        </div>
                      </Link>
                    )}
                    <Link href="/admin/carriers">
                      <div className={classNames(styles.navbarItem, pathName.includes('/carriers') && styles.active)}>
                        <p>Insurance Carriers</p>
                      </div>
                    </Link>
                    <Link href="/admin/statuses">
                      <div className={classNames(styles.navbarItem, pathName.includes('/statuses') && styles.active)}>
                        <p>Statuses</p>
                      </div>
                    </Link>
                    <Link href="/admin/sources">
                      <div className={classNames(styles.navbarItem, pathName.includes('/sources') && styles.active)}>
                        <p>Sources</p>
                      </div>
                    </Link>
                    <Link href="/admin/company">
                      <div className={classNames(styles.navbarItem, pathName.includes('/company') && styles.active)}>
                        <p>Companies</p>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {(currentUser.manager || currentUser.district_manager || currentUser.field_manager || this.isRM) && (
                <div
                  className={classNames(
                    'navbar-item with-dropdown',
                    styles.navbarItem,
                    styles.withDropdown,
                    pathName.includes('/admin') && styles.active
                  )}
                >
                  <p className={styles.navbarDropped}>{`${this.isRM ? 'Regional ' : ''}Manager`}</p>
                  <div className={styles.navsDropdown}>
                    <Link href="/admin/users">
                      <div className={classNames(styles.navbarItem, pathName.includes('/users') && styles.active)}>
                        <p>User Management</p>
                      </div>
                    </Link>
                    {!config().DISABLED_POOLS && currentUser.manager && (
                      <div
                        className={classNames(
                          'nested navbar-item with-dropdown',
                          styles.nested,
                          styles.navbarItem,
                          styles.withDropdown,
                          pathName.includes('/pools-management') && styles.active
                        )}
                      >
                        <div className={classNames(styles.navbarDropped, styles.textedMenu)}>
                          <a>
                            <p>Pool Management</p>
                          </a>
                        </div>
                        <div className={styles.nestedDropdown}>
                          <Link href="/admin/pools-management/groups">
                            <div
                              className={classNames(styles.navbarItem, pathName.includes('/groups') && styles.active)}
                            >
                              <p>Groups</p>
                            </div>
                          </Link>
                          <Link href="/admin/pools-management/pools">
                            <div
                              className={classNames(
                                styles.navbarItem,
                                pathName.includes('/pools-management/pools') && styles.active
                              )}
                            >
                              <p>Pools</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  get interactions() {
    return (
      <div className={styles.interactions}>
        {!this.isRestrictedUser && (
          <>
            {this.select}
            {this.search}
            {this.infoIcons}
          </>
        )}
        {this.profile}
      </div>
    );
  }

  get profile() {
    const { pathName } = this.state;
    const accountTypes = this.props.currentUser.profile.account_type;
    const showWebpage = (config().BROKERAGEASB && accountTypes !== 'agent') || !config().BROKERAGEASB;
    return (
      <div className={styles.profileWrapper}>
        <img className="profile-image" src={this.props.currentUser.profile.personal_photo.url} alt="profile" />
        <div
          className={classNames(
            'navbar-item with-dropdown',
            styles.navbarItem,
            styles.withDropdown,
            (pathName.includes('/account') || pathName.includes('/billing')) && styles.active
          )}
        >
          <div className={styles.withIcon}>
            <p className={styles.text}>
              {this.props.currentUser.profile.first_name} {this.props.currentUser.profile.last_name}{' '}
            </p>
            <i className="fas fa-chevron-down" />
          </div>
          <div className={classNames(styles.navsDropdown, styles.navsDropdownProfile)}>
            {!this.isRestrictedUser && (
              <>
                {showWebpage && !this.isRM && (
                  <div className={styles.navbarItem}>
                    <a href={`//${this.props.currentUser.webpage}`} target="_blank" rel="noreferrer">
                      <p>Webpage</p>
                    </a>
                  </div>
                )}
                <div
                  onClick={() => {
                    Router.push({
                      pathname: '/account/profile',
                      query: { tab: 'my_info' }
                    });
                    this.props.setActiveTab && this.props.setActiveTab();
                  }}
                  className={classNames(styles.navbarItem, pathName.includes('/profile') && styles.active)}
                >
                  <a>
                    <p>My Account</p>
                  </a>
                </div>
                {config().MARKETING_PROFILE && (
                  <div
                    onClick={() => Router.push({ pathname: '/signup' })}
                    className={classNames(
                      styles.navbarItem,
                      pathName.includes('/signup') && !pathName.includes('/admin') && styles.active
                    )}
                  >
                    <a>
                      <p>Marketing Profile</p>
                    </a>
                  </div>
                )}
              </>
            )}
            {this.props.currentAccount && !this.props.currentAccount['change_password?'] && (
              <>
                <Link href="/billing">
                  <div className={classNames(styles.navbarItem, pathName.includes('/billing') && styles.active)}>
                    <p>Billing Profile</p>
                  </div>
                </Link>
                <Link href="/api-keys">
                  <div className={classNames(styles.navbarItem, pathName.includes('/api-keys') && styles.active)}>
                    <p>API Keys</p>
                  </div>
                </Link>
              </>
            )}
            <LogoutButton logOut={this.props.logOut} />
          </div>
        </div>
      </div>
    );
  }

  get infoIcons() {
    return (
      <div className={styles.iconWrapper}>
        <div className={classNames('navbar-item with-dropdown', styles.navbarItem, styles.withDropdown)}>
          <Link href="/help-page" className={styles.navbarDropped}>
            <span className={classNames('mbri-info', this.state.pathName.includes('help') && styles.active)} />
          </Link>
        </div>
      </div>
    );
  }

  get search() {
    return (
      <div className={classNames('search', styles.search)}>
        <form name="searchForm">
          <div className={classNames('control has-icons-left', styles.control)}>
            <input
              name="search"
              onChange={this.validateOnChange}
              onKeyDown={this.setSearch}
              className="input"
              type="search"
              placeholder="Quick search"
              autoComplete="off"
              value={this.props.search}
            />
            <span className={classNames('icon is-small is-left', styles.icon)}>
              <i className="fas fa-search" />
            </span>
          </div>
        </form>
      </div>
    );
  }

  get select() {
    return (
      <div
        className={classNames(
          'navbar-item with-dropdown with-plus',
          styles.navbarItem,
          styles.withDropdown,
          styles.withPlus
        )}
      >
        <div className={styles.plusWrapper}>
          <a className={classNames(styles.navbarDropped, styles.droppedPlus)}>
            <i className="fas fa-plus" />
            <i className="fas fa-chevron-down" />
          </a>
          <div className={styles.navsDropdown}>
            <Link href="/contacts/new">
              <div className={styles.navbarItem}>
                <p>Contact</p>
              </div>
            </Link>
            <div
              className={classNames(
                'nested navbar-item with-dropdown',
                styles.nested,
                styles.navbarItem,
                styles.withDropdown
              )}
            >
              <div className={styles.navbarDropped}>
                <a>
                  <p>Calendar Event</p>
                </a>
              </div>
              <div className={styles.nestedDropdown}>
                <div className={styles.navbarItem} onClick={() => this.openCreateEventModalWithType(EVENT_APPOINTMENT)}>
                  <p>Appointment</p>
                </div>
                {!this.props.currentUser.c_1 && (
                  <>
                    <div
                      className={styles.navbarItem}
                      onClick={() => this.openCreateEventModalWithType(EVENT_FOLLOW_UP)}
                    >
                      <p>Follow Up</p>
                    </div>
                    <div className={styles.navbarItem} onClick={() => this.openCreateEventModalWithType(EVENT_TASK)}>
                      <p>Task</p>
                    </div>
                    <div className={styles.navbarItem} onClick={() => this.openCreateEventModalWithType(EVENT_OTHER)}>
                      <p>Other</p>
                    </div>
                    <div
                      className={styles.navbarItem}
                      onClick={() => this.openCreateEventModalWithType(EVENT_UNAVAILABLE)}
                    >
                      <p>Unavailable</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  get asUser() {
    const lastItem = getLoginStack().peek();
    if (lastItem === 'impersonating_as_rm' || lastItem === 'impersonating')
      return { label: 'Impersonator', value: 'back_as_impersonator' };
    if (lastItem === 'logged_as_admin') return { label: 'Admin', value: 'back_as_admin' };
    return null;
  }

  logBack = () => {
    this.asUser && this.props.logBack(this.asUser.value);
  };

  get adminHeader() {
    if (this.asUser)
      return (
        <div className={classNames(styles.adminTopnavbar)} id="adminTopnavbar">
          <div className={classNames(styles.adminTopnavbarContent)}>
            <span>
              You're in {this.asUser.label} mode for {this.props.currentUser.profile.first_name}{' '}
              {this.props.currentUser.profile.last_name} {this.props.currentUser.email}
            </span>
            <a onClick={this.logBack}>Exit {this.asUser.label} mode</a>
          </div>
        </div>
      );

    return null;
  }

  get header() {
    return (
      <header className={classNames('topnavbar-wrapper', styles.topnavbarWrapper)} id="navbar">
        <nav className={classNames('topnavbar', styles.topnavbar)}>
          <div className={classNames('navbar-nav', styles.navbarNav)}>
            {this.pages}
            {this.interactions}
          </div>
        </nav>
      </header>
    );
  }

  closeThemeModal = () => this.setState({ themeModalVisible: false });
  openCreateEventModalWithType = async type => {
    await this.setStateAsync({ eventType: type });
    this.openCreateEventModal();
  };
  openCreateEventModal = () => this.setState({ createEventModalIsVisible: true });

  validateOnChange = async event => {
    const value = event.target.value;
    const { pathName } = this.state;

    await this.props.setSearchContactToStore(value);
    await setSessionItem('search', value);
    await this.props.clearContacts();
    if (pathName === '/contacts') {
      this.props.quickSearchContacts(event);
    }
  };

  setSearch = async e => {
    e.persist();

    const { pathName } = this.state;
    const charCode = e.which ? e.which : e.keyCode;

    if (charCode === 13 && pathName !== '/contacts') {
      await this.props.setSearchContactToStore(e.target.value);
      await this.props.clearContacts();
      e.preventDefault();
      Router.push('/contacts');
    } else if (charCode === 13 && pathName === '/contacts') {
      e.preventDefault();
    }
  };

  removeRootClass = () => {
    const root = document.documentElement;
    if (root) {
      root.className = '';
    }
  };

  setRootClass = () => {
    const root = document.documentElement;
    if (root) {
      root.className = `${this.props.background} ${this.props.primaryColor}`;
    }
  };

  navigateToTraining = async () => {
    try {
      const verifyUrl =
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:8000/api/verify-user'
          : 'https://training.marketaccessdirect.net/api/verify-user';

      let responseData;

      const fetchResponse = await fetchAPIKey()();
      if (!fetchResponse.data.api_token || !fetchResponse.data.valid) {
        const refreshResponse = await refreshAPIKey()();
        responseData = refreshResponse.data;
      } else {
        responseData = fetchResponse.data;
      }

      const verifyResponse = await fetch(verifyUrl, {
        method: 'POST',
        headers: {
          token: responseData.api_token,
          domain: window?.location.hostname,
          Accept: 'application/json'
        }
      });
      const verifyResponseData = await verifyResponse.json();
      if (window && verifyResponseData.link) {
        window.location.href = verifyResponseData.link;
      }
    } catch (ex) {
      console.error('error:', ex);
    }
  };

  render() {
    return (
      <>
        {this.adminHeader}
        <div id="top">{this.header}</div>
        {this.state.themeModalVisible && (
          <Modal header onClose={this.closeThemeModal}>
            <ThemeSwitcher onClose={this.closeThemeModal} />
          </Modal>
        )}
        {this.state.createEventModalIsVisible && (
          <Modal classNames={'event-modal'} onClose={() => this.setState({ createEventModalIsVisible: false })}>
            <EventModal
              type={this.state.eventType}
              onClose={() => this.setState({ createEventModalIsVisible: false })}
            />
          </Modal>
        )}
        {this.state.trainingCertificationModalVisible && (
          <Modal
            classNames={'delete-modal'}
            onClose={() => this.setState({ trainingCertificationModalVisible: false })}
          >
            <TrainingCertification
              onSucess={async () => {
                await this.props.updateProfileFields({
                  profile: { training_viewed_at: moment() }
                });
                await this.navigateToTraining();
                this.setState({ trainingCertificationModalVisible: false });
              }}
              onClose={() => this.setState({ trainingCertificationModalVisible: false })}
            />
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  currentAccount: state.currentAccount.info,
  search: state.contacts.search,
  background: state.currentUser.profile.background,
  primaryColor: state.currentUser.profile.primary_color,
  accountType: state.currentUser.profile.account_type
});

export default connect(mapStateToProps, {
  logOut,
  setSearchContactToStore,
  logBack,
  clearContacts,
  updateProfileFields
})(withErrorBoundary(Header));
